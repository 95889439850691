export const checkVerifiedAndStatus = (isVerifiedByGov: boolean, status: string) => {
  const statusValue = status === "just_found";

  if (!isVerifiedByGov && statusValue) {
    return false;
  } else return true;
};

// if (isVerifiedByGov && statusValue) {
//   return true
// } else if (isVerifiedByGov && !statusValue) {
//   return true
// } else if (!isVerifiedByGov && statusValue) {
//   return false
// } else if (!isVerifiedByGov && !statusValue) {
//   return true
// }
