import { FC, memo } from "react";
import { IFinanceIntegrationTable } from "./IFinanceIntegrationTable";
import { TableDefault } from "../../../../../../components/Table/TableDefault";
import { getFinancesIntegrationCols } from "../../shared/dataList";
import { TableItem } from "../../../../../../components/Table/ui/TableItem";
import { Box, Stack, Typography } from "@mui/material";
import { StatusField } from "../../../../../../components/Table/ui/Fields/StatusField/StatusField";
import statusesStore from "../../../../../../store/statuses.store";
import { INTEGRATION_CARD_OPEN } from "../../../../../../shared/constants/searchParams";
import { useSearchParams } from "react-router-dom";

export const FinanceIntegrationTable: FC<IFinanceIntegrationTable> = memo((props) => {
  const { integrations, burgerItems, errorIntegrations } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const integrationCardOpen = (id: number) => {
    searchParams.set(INTEGRATION_CARD_OPEN, `${id}`);
    setSearchParams(searchParams);
  };

  return (
    <TableDefault
      tableRow={getFinancesIntegrationCols()}
      showBorderRadius={false}
      showBurgerCell
      showLastCell
      sx={{ mt: 1 }}
      maxHeight="308px"
      maxWidth="100%">
      {integrations.map((item) => {
        const newProps = {
          ...item,
          promo: item?.promokod?.code,
          contact: item?.channel?.contact || "—",
        };
        return (
          <TableItem
            onNavigate={integrationCardOpen}
            isShowNavigateIcon
            key={item.id}
            cells={getFinancesIntegrationCols()}
            {...newProps}
            burgerItemLists={burgerItems}
            borderColor={errorIntegrations?.includes(item.id) ? "#d32f2f" : undefined}
            lastCellContent={
              <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ maxWidth: "302px" }}>
                <StatusField
                  id={item.id}
                  type={item?.status?.code}
                  statusStore={statusesStore?.statuses}
                  readOnly
                  onChangeStatus={() => ""}
                  update_id={1}
                  onUpdateStatus={() => "" as any}
                  isVerifiedByGov={newProps.channel.isVerifiedByGov}
                />
                {item.firstIntegrationWithChannel === true && (
                  <Box
                    sx={{
                      background: "rgb(58, 167, 255)",
                      p: "6px",
                      borderRadius: "4px",
                      textAlign: "center",
                    }}>
                    <Typography noWrap color={"#fff"}>
                      {"Первый выпуск"}
                    </Typography>
                  </Box>
                )}
              </Stack>
            }
          />
        );
      })}
    </TableDefault>
  );
});
