import { FC, useState } from 'react'
import { IPaymentStatus } from './IPaymentStatus'
import { StatusesList } from '../../../../Statuses/StatusesList'
import statusesStore from '../../../../../store/statuses.store'
import { observer } from 'mobx-react-lite'
import { AxiosError } from 'axios'
import PaymentsService from '../../../../../services/payments/payments.service'
import { IPaymentUpdateStatusRequest } from '../../../../../services/payments/IPaymentsRequest'
import { checkNumberBoolean } from '../../../../../shared/functions/functions'

export const PaymentStatus:FC<IPaymentStatus> = observer((props) => {
  const {
    id, 
    type, 
    statusCode,
    readOnly = true,
    wrap = false,
    update_id,
    onChangeStatus,
    isShowPaymentModal = false,
    paymentType,
    totalSumm,
    isStopAlert,
    stopAlertText,
    stopCodeInList,
    isVerifiedByGov
  } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [isShowAlert, setShowAlert] = useState(false)
  
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<null | string>(null)

  const changeStatus = (code:string) => {
    if(type !== 'integration' && checkNumberBoolean(update_id)){
      setLoading(true)

      const option: IPaymentUpdateStatusRequest = {
        id,
        update_id:update_id!,
        newStatus:code,
        entity:type
      }
      PaymentsService.updateStatus(option)
        .then(res=>{
          if(onChangeStatus){
            onChangeStatus(res.data)
          }
          
          handleClose()
          setLoading(false)
        })
        .catch(err=>{
          if(err instanceof AxiosError){
            setError(err?.response?.data?.message)
            setLoading(false)
          }
        })

    }
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <StatusesList
      paymentType={paymentType=== 'Взаимозачет' ? 'netting' : ''}
      type={statusCode}
      anchorEl={anchorEl}
      onOpenList={handleClick}
      onCloseList={handleClose}
      changeStatus={changeStatus}
      statusStore={type === 'integration' ? statusesStore.statuses : type === 'payment' ? statusesStore.payments : statusesStore.pay}
      loading={isLoading}
      showAlertSuccess={isShowAlert}
      closeAlertSuccess={()=>setShowAlert(false)}
      isShowAlerError={isError}
      closeAlertError={()=>setError(null)}
      readOnly={readOnly}
      wrap={wrap}
      payAlertModal={isShowPaymentModal}
      isStopAlert={isStopAlert}
      stopAlertText={stopAlertText}
      stopCodeInList={stopCodeInList}
      isVerifiedByGov={isVerifiedByGov}
      payContentData={isShowPaymentModal && checkNumberBoolean(totalSumm) ?
        {
          paymentType:paymentType || '',
          plannedPaidAt:new Date()?.toISOString(),
          totalSumm:totalSumm!
        }
      : undefined
    }
    />
  )
})